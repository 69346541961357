<template>
  <Layout>
    <template v-if="this.$route.name == 'add-post'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row" id="addpost">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard
              @on-complete="submitData"
              color="#556ee6"
              enctype="multipart/form-data"
              ref="post"
              :finishButtonText="
                this.$route.name == 'add-post' ? 'Save Data' : 'Update Data'
              "
              back-button-text="Go Back!"
              next-button-text="Go Next!"
            >
              <tab-content
                title="Post Details"
                icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()"
              >
                <div class="row">
                  <b-form-group class="col-12">
                    <label for="pl" v-if="$route.name == 'add-post'"
                      >Choose Post Type <span style="color: red">*</span></label
                    >
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="form.action_type"
                      :options="actionType"
                      @change="changeActionType"
                      name="radio-options"
                      :class="{
                        'is-invalid': submitted && $v.form.action_type.$invalid,
                      }"
                    >
                    </b-form-radio-group>
                    <div
                      v-if="submitted && !$v.form.action_type.required"
                      class="invalid-feedback"
                    >
                      Post Type is required.
                    </div>
                  </b-form-group>
                  <div class="col-12">
                    <label v-if="form.action_type == 'post_an_update'"
                      >Post Title <span style="color: red">*</span></label
                    >
                    <label v-else
                      >Presentation Title
                      <span style="color: red">*</span></label
                    >
                    <div>
                      <input
                        type="text"
                        :placeholder="
                          form.action_type == 'post_an_update'
                            ? 'Enter Post Title'
                            : 'Enter Presentation Title'
                        "
                        v-model="form.title"
                        @keyup.prevent="slugify"
                        :class="{
                          'is-invalid': submitted && $v.form.title.$invalid,
                        }"
                        class="w-100 form-control"
                      />
                      <div
                        v-if="submitted && !$v.form.title.required"
                        class="invalid-feedback mb-2"
                      >
                        <span v-if="form.action_type == 'post_an_update'"
                          >Post Title is required.</span
                        >
                        <span v-else>Presentation Title is required.</span>
                      </div>
                    </div>
                  </div>
                  <b-form-group class="col-6 mt-3">
                    <label for="slug"
                      >Slug <span style="color: red">*</span></label
                    >
                    <b-form-input
                      id="slug"
                      v-model="form.slug"
                      placeholder="Enter Slug"
                      :class="{
                        'is-invalid': submitted && $v.form.slug.$invalid,
                      }"
                    >
                    </b-form-input>
                    <div
                      v-if="submitted && !$v.form.slug.required"
                      class="invalid-feedback"
                    >
                      Slug is required.
                    </div>
                  </b-form-group>
                  <b-form-group
                    label-for="ppt"
                    class="col-6 mt-3"
                    v-if="form.action_type == 'presentation'"
                  >
                    <label
                      >Add Presentation [Upload .ppt and .pptx files] [Upload
                      Max File Size : 20 MB]</label
                    >
                    <b-form-file
                      id="case_ppt"
                      ref="case_ppt"
                      accept=".ppt, .pptx"
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'file_name')"
                    >
                    </b-form-file>
                  </b-form-group>
                  <div
                    class="col-6 mt-3"
                    v-if="isPostUrl && form.action_type == 'post_an_update'"
                  >
                    <b-form-group>
                      <label>Post URL </label>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 0.2fr;
                          grid-gap: 10px;
                        "
                      >
                        <b-form-input
                          type="text"
                          id="url_link"
                          v-model="form.url_link"
                          placeholder="Enter Post URL"
                          @change="changePostUrl"
                        />
                        <b-button
                          class="btn-info btn-sm"
                          style="font-size: 18px"
                          @click="fetchMetadata"
                          :disabled="!form.url_link"
                          id="fetch-url"
                          >Fetch URL</b-button
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <b-form-group class="col-12">
                    <label for="i3"
                      >Description <span style="color: red">*</span></label
                    >
                    <ckeditor
                      :editor="editor"
                      v-model="form.description"
                      :class="{
                        'is-invalid': submitted && $v.form.description.$invalid,
                      }"
                    ></ckeditor>
                    <div
                      v-if="submitted && !$v.form.description.required"
                      class="invalid-feedback"
                    >
                      Description is required.
                    </div>
                  </b-form-group>
                </div>
                <div
                  class="row"
                  v-if="form.action_type == 'post_an_update' && isImageVideo"
                >
                  <b-form-group class="case-img col-12">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label for="case_image"
                        >Add Image/Videos [Multiple Selection allowed] [Upload
                        Max File Size : 20 MB]</label
                      >
                      <div
                        @click="clearImageVideo"
                        style="cursor: pointer"
                        v-if="images_videos.length > 0"
                      >
                        <img src="@/assets/images/close.png" height="18px" />
                      </div>
                    </div>
                    <b-form-file
                      v-model="removeItem"
                      ref="post_images_videos"
                      accept="image/png, image/jpeg, image/jpg, video/mp4, video/x-m4v, video/avi, video/*,video/x-msvideo, .hevc, .h265, .mov, video/mov, video/quicktime"
                      multiple
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'image_video')"
                    >
                    </b-form-file>
                    <template
                      v-if="
                        $route.name == 'edit-post' && images_videos.length > 0
                      "
                    >
                      <div class="post_images">
                        <template v-for="(item, index) in images_videos">
                          <div
                            style="position: relative"
                            id="delete-img"
                            :key="index"
                          >
                            <div
                              @click.prevent="
                                deleteImageVideo(
                                  item.item_id,
                                  index,
                                  item.media_type
                                )
                              "
                              v-if="item.item_id"
                            >
                              <i
                                v-if="item.item_id"
                                class="mdi mdi-close-circle cross"
                                :style="{
                                  top:
                                    item.media_type == 'image'
                                      ? '-9px'
                                      : '-12px',
                                }"
                              ></i>
                            </div>
                            <img
                              :key="index"
                              :src="item.image_name"
                              v-if="item.media_type == 'image'"
                            />
                            <video
                              width="300px"
                              height="180px"
                              controls
                              v-if="item.media_type == 'video'"
                            >
                              <source
                                :src="item.image_name"
                                type="video/mp4"
                                :key="index"
                              />
                            </video>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template
                      v-if="
                        $route.name == 'add-post' && images_videos.length > 0
                      "
                    >
                      <div class="post_images">
                        <div
                          v-for="(item, index) in images_videos"
                          :key="index"
                        >
                          <img
                            :key="index"
                            :src="item.image_name"
                            width="128px"
                            height="128px"
                            v-if="item.media_type == 'image'"
                          />
                          <video
                            width="320px"
                            height="200px"
                            controls
                            v-if="item.media_type == 'video'"
                          >
                            <source
                              :src="item.image_name"
                              type="video/mp4"
                              :key="index"
                            />
                          </video>
                        </div>
                      </div>
                    </template>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group label-for="country" class="col-6">
                    <label>Country </label>
                    <multiselect
                      @search-change="fetchAllCountry"
                      id="input-multi"
                      v-model="form.country"
                      :options="allCountry"
                      :multiple="true"
                      track-by="id"
                      label="name"
                      placeholder="Type here to search"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group label-for="community" class="col-6">
                    <label>Community <span style="color: red">*</span> </label>
                    <multiselect
                      v-model="form.communities_selected"
                      @search-change="fetchCommunity"
                      :options="getCommunity"
                      :multiple="true"
                      track-by="id"
                      label="title"
                      placeholder="Type here to search"
                      :class="{
                        'is-invalid':
                          submitted && $v.form.communities_selected.$invalid,
                      }"
                    >
                      <span slot="noOptions"> Type here to search </span>
                    </multiselect>
                    <div
                      v-if="submitted && !$v.form.communities_selected.required"
                      class="invalid-feedback"
                    >
                      Community is required.
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Sub Speciality"
                    label-for="video_sub_spec"
                    class="col-6"
                  >
                    <multiselect
                      id="video_sub_spec"
                      v-model="form.sub_specialities"
                      :options="subspecialities"
                      :multiple="true"
                      track-by="id"
                      label="name"
                      placeholder="Type here to search"
                    >
                      <span slot="noOptions"> Type here to search </span>
                    </multiselect>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label>Member <span style="color: red">*</span></label>
                    <multiselect
                      @search-change="fetchMemberList"
                      id="member"
                      v-model="form.member_tagging"
                      :options="memberList"
                      :multiple="false"
                      track-by="member_id"
                      label="fnameAndLname"
                      placeholder="Type here to search"
                      :class="{
                        'is-invalid':
                          submitted && $v.form.member_tagging.$invalid,
                      }"
                    >
                      <span slot="noOptions"> Type here to search </span>
                    </multiselect>
                    <div
                      v-if="submitted && !$v.form.member_tagging.required"
                      class="invalid-feedback"
                    >
                      Member is required.
                    </div>
                  </b-form-group>
                  <b-form-group label-for="meta_title" class="col-6">
                    <label>Meta Title</label>
                    <b-form-input
                      type="text"
                      id="meta_title"
                      v-model="form.meta_title"
                      placeholder="Enter Meta Title"
                    />
                  </b-form-group>
                  <b-form-group label-for="meta_keywords" class="col-6">
                    <label>Meta Keywords</label>
                    <b-form-input
                      type="text"
                      id="meta_keywords"
                      v-model="form.meta_keywords"
                      placeholder="Enter Meta Keywords"
                    />
                  </b-form-group>
                  <b-form-group class="col-12">
                    <label for="i3">Meta Description</label>
                    <ckeditor
                      :editor="editor"
                      v-model="form.meta_desc"
                    ></ckeditor>
                  </b-form-group>
                  <b-form-group class="col-12">
                      <div style="display: flex; gap: 10px; padding: 2px 0">
                        <b-form-checkbox v-model="form.visible_on_doctor_profile"
                          >Visible to doctor profile only</b-form-checkbox
                        >
                      </div>
                  </b-form-group>
                </div>
              </tab-content>

              <tab-content
                title="Post Preview"
                icon="mdi mdi-post"
                v-if="
                  (form.action_type == 'post_an_update' &&
                    ($route.name == 'add-post' ||
                      $route.name == 'edit-post')) ||
                  (form.action_type == 'presentation' &&
                    $route.name == 'edit-post' &&
                    showFileName)
                "
              >
                <div class="row" v-if="form.member_tagging">
                  <div
                    class="col-12 mt-4 mb-3"
                    style="display: grid; justify-content: center"
                  >
                    <h5 class="text-center">
                      Preview of
                      <span
                        v-if="form.action_type == 'post_an_update'"
                        style="font-weight: 900"
                        >Post an Update</span
                      >
                      <span
                        v-if="form.action_type == 'presentation'"
                        style="font-weight: 900"
                      >
                        Presentation
                      </span>
                    </h5>
                    <div class="post_grid">
                      <div class="post_head_grid">
                        <img
                          :src="
                            form.member_tagging.profile_image ||
                            require('@/assets/images/only_doctor.png')
                          "
                          height="50"
                          width="50"
                          style="justify-self: center"
                        />
                        <div class="post_member_grid">
                          <div class="handle_line_clamp">
                            <span v-if="form.member_tagging.fnameAndLname">
                              {{
                                form.member_tagging.fnameAndLname.replace(
                                  /[-0-9]/g,
                                  ""
                                )
                              }}</span
                            >
                            <span v-else>[Member Name]</span>
                          </div>
                          <div class="handle_line_clamp">
                            <span v-if="form.member_tagging.city">{{
                              form.member_tagging.city
                            }}</span>
                            <span v-else>[City]</span>
                            <span
                              v-if="
                                form.member_tagging.speciality_id &&
                                member_speciality
                              "
                            >
                              - {{ member_speciality }}</span
                            >
                            <span v-else> - [Speciality Name]</span>
                          </div>
                        </div>
                      </div>
                      <div class="px-4">
                        <span v-if="form.title" class="dc_desc">{{
                          form.title
                        }}</span>
                        <span v-else>[Title]</span>
                      </div>
                      <div
                        class="px-4 post_community"
                        v-if="form.communities_selected.length > 0"
                      >
                        <template
                          v-for="(item, index) in form.communities_selected"
                        >
                          <span :key="index" class="mr-1">
                            #{{ item.title }}
                          </span>
                        </template>
                      </div>
                      <div v-if="form.url_link" class="px-4">
                        <a
                          :href="form.url_link"
                          target="blank"
                          class="post_url"
                          >{{ form.url_link }}</a
                        >
                      </div>
                      <div
                        :class="`${
                          images_videos ? 'mt-2' : 'mt-0'
                        } position-relative`"
                      >
                        <span class="post_type mx-2">Post</span>
                        <carousel
                          v-if="images_videos.length > 0"
                          :per-page="1"
                          :mouse-drag="false"
                          :navigationEnabled="true"
                          :paginationEnabled="false"
                        >
                          <slide
                            v-for="(item, index) in images_videos"
                            :key="index"
                          >
                            <img
                              width="500"
                              height="380"
                              :src="item.image_name"
                              alt="image slot"
                              v-if="item.media_type == 'image'"
                              :key="index"
                            />
                            <video
                              width="500"
                              height="380"
                              controls
                              v-if="item.media_type == 'video'"
                              :key="index"
                            >
                              <source :src="item.image_name" type="video/mp4" />
                            </video>
                          </slide>
                        </carousel>
                        <img
                          :src="form.post_meta_image"
                          style="object-fit: contain"
                          height="100%"
                          width="500"
                          v-if="form.post_meta_image"
                        />
                      </div>
                      <span
                        class="px-2 post_community"
                        v-if="form.post_meta_title"
                        >{{ form.post_meta_title }}</span
                      >
                      <span
                        class="px-2 post_meta_desc"
                        v-if="form.post_meta_desc"
                        >{{ form.post_meta_desc }}</span
                      >
                      <div
                        :class="`dc_useful_interaction d-flex justify-content-between align-items-center px-3 pb-3 ${
                          images_videos.length > 0 || form.post_meta_desc
                            ? 'pt-0'
                            : 'pt-3'
                        }`"
                      >
                        <div>
                          <img
                            src="@/assets/images/usefullInteraction/usefull.png"
                          />
                          <span class="ml-2">Useful</span>
                        </div>
                        <div>
                          <img
                            src="@/assets/images/usefullInteraction/save.png"
                          />
                          <span class="ml-2">Save</span>
                        </div>
                        <div>
                          <img
                            src="@/assets/images/usefullInteraction/comment.png"
                          />
                          <span class="ml-2">Comment</span>
                        </div>
                        <div>
                          <img
                            src="@/assets/images/usefullInteraction/interaction.png"
                          />
                          <span class="ml-2">Share</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import PostMixin from "../../../mixins/ModuleJs/post";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { FormWizard, TabContent } from "vue-form-wizard";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      submitted: false,
      title1: "Add Post",
      title2: "Edit Post",
      items: [{ text: "Back", href: "/post" }, { text: "Data" }],
    };
  },
  validations: {
    form: {
      title: { required },
      description: { required },
      action_type: { required },
      member_tagging: { required },
      communities_selected: { required },
      slug: { required },
    },
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (
        this.$v.form.title.$invalid ||
        this.$v.form.description.$invalid ||
        this.$v.form.member_tagging.$invalid ||
        this.$v.form.slug.$invalid ||
        this.$v.form.communities_selected.$invalid
      ) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      if (
        this.file_name == "" &&
        this.form.action_type == "presentation" &&
        this.$route.name == "add-post"
      ) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Presentation is required",
        });
        return false;
      }
      if (
        this.form.url_link != "" &&
        this.images_videos.length > 0 &&
        this.$route.name == "add-post"
      ) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Select either Post URL or Images/Videos",
        });
        return false;
      }
      if (
        this.form.url_link != "" &&
        this.form.action_type == "post_an_update"
      ) {
        console.log("going");
        if (this.form.post_meta_title == "") {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetch URL before submitting",
          });
          document.getElementById("fetch-url").style.border = "2px solid red";
          return false;
        }
      }
      return true;
    },
  },
  mixins: [MixinRequest, PostMixin],
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
    ckeditor: CKEditor.component,
  },
};
</script>
